import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition,
} from '@headlessui/react';
import PropTypes from 'prop-types';
import Rating from 'components/Rating';
import ProgressBar from 'components/ProgressBar';
import CategoryIcon from 'components/CategoryIcon';

import {
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
  EyeIcon,
} from '@heroicons/react/24/outline';

const DEFAULT_IMAGE = 'https://via.placeholder.com/150?text=No+Image';

const EventCardWrapper = ({ event, user, hasNavigation, small, isActive }) => {
  const navigate = useNavigate();

  const handleNavigateToBookingStatus = () => {
    navigate(`/booking-status/${event.bookingId}`, {
      state: { event: event },
    });
  };

  const handleNavigateToEventDetail = () => {
    navigate(`/event/${event.id}`);
  };

  const handleNavigateToEventEdit = () => {
    navigate(`/event/${event.id}/edit`);
  };

  const handleDeleteEvent = () => {
    console.log(`Deleting event: ${event.id}`);
  };

  if (!event) {
    return null;
  }

  const EventCard = () => {
    return (
      <div
        className={`flex flex-col rounded-3xl overflow-hidden bg-white shadow-md hover:shadow-lg transition-shadow duration-200 relative ${
          isActive ? 'border-2 border-gray-400 shadow-xl' : ''
        }`}
      >
        <div className="flex h-full">
          <div className="flex-none p-4">
            <img
              className="rounded-md w-[120px] h-[120px]"
              src={event.image || DEFAULT_IMAGE}
              alt={event.title || 'Event'}
            />
          </div>
          <div className="flex pl-4 items-center w-full">
            <div>
              <h3 className="font-bold text-lg text-gray-800 truncate">
                {event.name}
              </h3>
              <p
                className="text-gray-500 text-sm truncate max-w-full"
                title={event.location}
              >
                {event.location}
              </p>
              {event.rating && <Rating rating={event.rating} />}
              <div className="mt-2">
                <span className="px-2 py-1 rounded-2xl bg-gray-200 text-gray-600 text-xs">
                  {event.dateRange}
                </span>
              </div>
            </div>
            <div className="mt-2 flex flex-row space-x-1">
              <CategoryIcon macro="arts_crafts" className="w-5 h-5" />
              <CategoryIcon macro="fashion_apparel" className="w-5 h-5" />
              <CategoryIcon macro="food_drinks" className="w-5 h-5" />
            </div>
            {small && (
              <Menu as="div" className="absolute top-4 right-4 z-100">
                <MenuButton>
                  <EllipsisHorizontalIcon className="h-5 w-5 text-gray-600 hover:text-gray-800" />
                </MenuButton>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 w-26 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <MenuItem>
                      {({ active }) => (
                        <button
                          onClick={handleNavigateToBookingStatus}
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } flex items-center px-4 py-2 text-xs text-gray-700`}
                        >
                          <EyeIcon className="w-4 h-4 mr-2" />
                          Manage
                        </button>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <button
                          onClick={handleNavigateToEventDetail}
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } flex items-center px-4 py-2 text-xs text-gray-700`}
                        >
                          <EyeIcon className="w-4 h-4 mr-2" />
                          View
                        </button>
                      )}
                    </MenuItem>
                    {user === 'Promoter' && (
                      <MenuItem>
                        {({ active }) => (
                          <button
                            onClick={handleNavigateToEventEdit}
                            className={`${
                              active ? 'bg-gray-100' : ''
                            } flex items-center px-4 py-2 text-xs text-gray-700`}
                          >
                            <PencilIcon className="w-4 h-4 mr-2" />
                            Edit
                          </button>
                        )}
                      </MenuItem>
                    )}
                    <MenuItem>
                      {({ active }) => (
                        <button
                          onClick={handleDeleteEvent}
                          className={`${
                            active ? 'bg-gray-100' : ''
                          } flex items-center px-4 py-2 text-xs text-gray-700 w-full`}
                        >
                          <TrashIcon className="w-4 h-4 mr-2" />
                          Delete
                        </button>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            )}
          </div>
        </div>
        {!small && (
          <>
            <div className="px-4 pb-4">
              <p className="text-gray-500 text-sm truncate-2-lines">
                {event.description}
              </p>
            </div>
            <div className="px-4 pb-4 flex justify-between items-center">
              <div className="w-1/3">
                <ProgressBar
                  current={Math.floor(Math.random() * 100)}
                  total={100}
                />
              </div>
              <div className="ml-4 p-2 bg-yellow-100 rounded-2xl cursor-pointer">
                <p className="text-gray-900 text-[10px] font-bold">
                  Prices start at:
                </p>
                <p className="text-gray-900 text-xs font-bold">
                  {event.price}€
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return hasNavigation ? (
    <div
      onClick={handleNavigateToEventDetail}
      className="no-underline text-current"
    >
      {EventCard()}
    </div>
  ) : (
    EventCard()
  );
};

EventCardWrapper.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    dateRange: PropTypes.string,
    capacity: PropTypes.string,
    price: PropTypes.number,
    rating: PropTypes.number,
    description: PropTypes.string,
  }),
  hasNavigation: PropTypes.bool,
  isActive: PropTypes.bool,
  small: PropTypes.bool,
  user: PropTypes.string,
};

export default EventCardWrapper;
