import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import EventCardWrapper from 'components/EventCardWrapper';
import EventCardList from 'components/EventCardList';
import BrandCardWrapper from 'components/BrandCard';
import BrandCardList from 'components/BrandCardList';
import EventSearchBar from 'components/EventSearchBar';
import MapButton from 'components/MapButton';
import SortBar from 'components/SortBar';

import { EVENT_LIST } from '../../mockData';
import { BRAND_LIST } from '../../mockData';

const ListingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayType, setDisplayType] = useState('squared');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentPage = location.pathname;
  const searchType = params.get('searchType') || 'events';

  const { userType, isAuthenticated } = useSelector((state) => state.auth);

  // TODO: Fetch events from API
  // useEffect(() => {
  //   dispatch(getAllEvents());
  // }, [dispatch]);

  const handleSearch = () => {
    navigate('/events');
  };

  const handleNavigateToMap = () => {
    // Implementation of navigation to map
    console.log('Navigate to map');
  };

  const CardsContainer = () => {
    const list = searchType === 'events' ? EVENT_LIST : BRAND_LIST;
    const Wrapper =
      searchType === 'events' ? EventCardWrapper : BrandCardWrapper;
    const ListComponent =
      searchType === 'events' ? EventCardList : BrandCardList;

    return (
      <div className="">
        <div
          className={`grid ${displayType === 'squared' ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'} gap-4`}
        >
          {list.map((item) =>
            displayType === 'squared' ? (
              <Wrapper
                key={item.id + Math.random(100)}
                hasNavigation
                event={searchType === 'events' ? item : undefined}
                brand={searchType === 'brands' ? item : undefined}
              />
            ) : (
              <ListComponent
                key={item.id + Math.random(100)}
                hasNavigation
                event={searchType === 'events' ? item : undefined}
                brand={searchType === 'brands' ? item : undefined}
                isGuest={!isAuthenticated}
              />
            )
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-100 pb-6">
      <div className="flex justify-center py-4">
        <EventSearchBar
          onSearch={handleSearch}
          currentPage={currentPage}
          currentSearch={searchType}
          onChangeSearch={() => {}}
        />
      </div>
      <div className="flex justify-center">
        <div className="w-full max-w-screen-lg">
          <SortBar displayType={displayType} setDisplayType={setDisplayType} />
          {CardsContainer()}
        </div>
      </div>
      {searchType === 'events' ? (
        <div className="fixed bottom-0 left-0 right-0">
          <div className="bg-gradient-to-t from-gray-300 to-transparent pb-20">
            <Link
              to="/events/map"
              className="container mx-auto px-4 flex justify-center"
            >
              <MapButton hasIcon />
            </Link>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ListingPage;
