import React from 'react';
import PropTypes from 'prop-types';

const BookingConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  selectedPlace,
  user,
  event,
}) => {
  const totalAmount = selectedPlace ? Number(selectedPlace.price) * 1.28 : 0;

  if (!isOpen) return null;

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      onClose();
    }
  };

  return (
    <div
      id="modal-overlay"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={handleOutsideClick}
    >
      <div className="bg-gray-100 rounded-3xl shadow-lg p-8 w-[90%] max-w-3xl relative space-y-8">
        {/* Title */}
        <h2 className="text-2xl font-bold text-yellow-500 text-center">
          Great! Please confirm your booking details.
        </h2>

        {/* How it works Section */}
        <div className="bg-white p-4 rounded-md">
          <h3 className="text-center italic">How it works</h3>
          <ol className="text-sm text-gray-600 space-y-4 mt-2">
            <li>
              <span className="px-2 py-1 mr-2 bg-gray-300 font-bold rounded-full">
                1
              </span>{' '}
              Booking request by brand.
            </li>
            <li>
              <span className="px-2 py-1 mr-2 bg-gray-300 font-bold rounded-full">
                2
              </span>{' '}
              Promoter approves request or not
            </li>
            <li>
              <span className="px-2 py-1 mr-2 bg-gray-300 font-bold rounded-full">
                3
              </span>{' '}
              After approval, brand pays within 12 hours, and the booking is
              confirmed.
            </li>
          </ol>
        </div>

        {/* Booking Details */}
        <div className="flex justify-center items-center space-x-4">
          <p className="font-bold text-lg text-gray-700">
            {user.username || 'Brand Name'}
          </p>
          <p className="font-bold text-yellow-02 text-3xl">{'〉'}</p>
          <p className="font-bold text-lg text-gray-700">
            {event.name || 'Event Name'}
          </p>
          <p className="font-bold text-yellow-02 text-3xl">{'〉'}</p>
          <p className="font-bold text-lg text-gray-700">
            {selectedPlace.name} - {selectedPlace.size}
          </p>
        </div>

        {/* Confirm Booking Button */}
        <div className="flex justify-center">
          <button
            className="py-4 px-8 bg-yellow-03 text-white font-bold rounded-full shadow hover:bg-yellow-400 transition-all duration-300"
            onClick={onConfirm}
          >
            Confirm Booking Request
          </button>
        </div>

        <div className="flex justify-center">
          <div className="flex w-1/4" />
          {/* Additional Notes */}
          <div className="flex flex-col w-2/4">
            <p className="text-sm text-center text-gray-400">
              Promoter will be notified about your request.
            </p>
            <p className="text-sm text-center text-gray-400">
              You won’t be charged yet.
            </p>
          </div>
          {/* Total Amount */}
          <div className="flex w-1/4 justify-end">
            <div className="flex flex-col items-end">
              <p className="text-xs text-gray-600">Total including taxes:</p>
              <p className="text-lg font-bold text-gray-900">
                €{totalAmount.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BookingConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  selectedPlace: PropTypes.shape({
    price: PropTypes.number,
    brandName: PropTypes.string,
    eventName: PropTypes.string,
    size: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default BookingConfirmationModal;
