import axios from '../../utils/axiosConfig';

// Action Types
export const CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST';
export const CREATE_BOOKING_SUCCESS = 'CREATE_BOOKING_SUCCESS';
export const CREATE_BOOKING_FAIL = 'CREATE_BOOKING_FAIL';

export const GET_BOOKINGS_REQUEST = 'GET_BOOKINGS_REQUEST';
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS';
export const GET_BOOKINGS_FAIL = 'GET_BOOKINGS_FAIL';

export const UPDATE_BOOKING_REQUEST = 'UPDATE_BOOKING_REQUEST';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAIL = 'UPDATE_BOOKING_FAIL';

export const DELETE_BOOKING_REQUEST = 'DELETE_BOOKING_REQUEST';
export const DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS';
export const DELETE_BOOKING_FAIL = 'DELETE_BOOKING_FAIL';

export const APPROVE_BOOKING_SUCCESS = 'APPROVE_BOOKING_SUCCESS';
export const REJECT_BOOKING_SUCCESS = 'REJECT_BOOKING_SUCCESS';
export const BOOKING_ACTION_FAIL = 'BOOKING_ACTION_FAIL';

export const GET_BRAND_EVENTS_REQUEST = 'GET_BRAND_EVENTS_REQUEST';
export const GET_BRAND_EVENTS_SUCCESS = 'GET_BRAND_EVENTS_SUCCESS';
export const GET_BRAND_EVENTS_FAIL = 'GET_BRAND_EVENTS_FAIL';

export const GET_BOOKING_REQUEST = 'GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAIL = 'GET_BOOKING_FAIL';

// Create Booking
export const createBooking = (bookingData) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  dispatch({ type: CREATE_BOOKING_REQUEST });

  try {
    const res = await axios.post('/bookings', bookingData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: CREATE_BOOKING_SUCCESS, payload: res.data });
    return res.data;
  } catch (error) {
    dispatch({
      type: CREATE_BOOKING_FAIL,
      payload: error.response?.data?.message || 'Failed to create booking',
    });
    throw error;
  }
};

// Get All Bookings
export const getAllBookings = () => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  dispatch({ type: GET_BOOKINGS_REQUEST });

  try {
    const res = await axios.get('/bookings', {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_BOOKINGS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: GET_BOOKINGS_FAIL,
      payload: error.response?.data?.message || 'Failed to fetch bookings',
    });
  }
};

export const getBooking = (id) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  dispatch({ type: GET_BOOKING_REQUEST });

  try {
    const res = await axios.get(`/bookings/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_BOOKING_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: GET_BOOKING_FAIL,
      payload: error.response?.data?.message || 'Failed to fetch booking',
    });
  }
};

// Update Booking
export const updateBooking = (id, updateData) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  dispatch({ type: UPDATE_BOOKING_REQUEST });

  try {
    const res = await axios.put(`/bookings/${id}`, updateData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: UPDATE_BOOKING_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: UPDATE_BOOKING_FAIL,
      payload: error.response?.data?.message || 'Failed to update booking',
    });
  }
};

// Delete Booking
export const deleteBooking = (id) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  dispatch({ type: DELETE_BOOKING_REQUEST });

  try {
    await axios.delete(`/bookings/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: DELETE_BOOKING_SUCCESS, payload: id });
  } catch (error) {
    dispatch({
      type: DELETE_BOOKING_FAIL,
      payload: error.response?.data?.message || 'Failed to delete booking',
    });
  }
};

// Approve Booking
export const approveBooking = (id) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  try {
    const res = await axios.put(
      `/bookings/approve/${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    dispatch({ type: APPROVE_BOOKING_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: BOOKING_ACTION_FAIL,
      payload: error.response?.data?.message || 'Failed to approve booking',
    });
  }
};

// Reject Booking
export const rejectBooking = (id) => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  try {
    const res = await axios.put(
      `/bookings/reject/${id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    dispatch({ type: REJECT_BOOKING_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: BOOKING_ACTION_FAIL,
      payload: error.response?.data?.message || 'Failed to reject booking',
    });
  }
};

// Get Events for a Brand's Bookings
export const getBrandEvents = () => async (dispatch, getState) => {
  const { auth } = getState();
  const token = auth.token;

  dispatch({ type: GET_BRAND_EVENTS_REQUEST });

  try {
    const res = await axios.get('/bookings/brand/events', {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: GET_BRAND_EVENTS_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: GET_BRAND_EVENTS_FAIL,
      payload: error.response?.data?.message || 'Failed to fetch brand events',
    });
  }
};
