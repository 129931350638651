import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import {
  RectangleGroupIcon,
  Square3Stack3DIcon,
  PlusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { BRAND_LIST } from 'mockData';
import { useModal } from '../../context/ModalContext';

const BookingBubble = ({
  action,
  isSticky,
  isBrand,
  selectedPlace,
  lowestPrice,
  bookingAction,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();
  const { showLoginModal } = useModal();

  const mockPricing = {
    t2bFee: Number(selectedPlace?.price) * 0.05,
    vat: Number(selectedPlace?.price) * 0.23,
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  console.log('selectedPlaceBUBBLE', selectedPlace);
  const renderPricing = () => {
    if (!isBrand || !selectedPlace) {
      return null;
    }

    const price = Number(selectedPlace.price); // Convert price to a number
    const total = price + mockPricing.t2bFee + mockPricing.vat;

    return (
      <div className="my-6 border-t border-b border-gray-300 flex flex-col items-center w-full space-y-2">
        <p className="pt-4 text-xs font-thin">your selection</p>
        <div className="flex flex-row w-full items-center">
          <p className="text-md font-bold">Plan:</p>
          <p className="ml-4 text-md font-bold text-yellow-02">
            {selectedPlace.name}
          </p>
          <p className="ml-2 text-md font-bold text-yellow-02">
            {selectedPlace.size}
          </p>
        </div>
        <Transition
          show={isExpanded}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <div className="w-full">
            <div className="flex flex-row justify-between">
              <span className="text-gray-500">Plan</span>
              <span className="text-gray-500">{price.toFixed(2)}€</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-500">T2b Service Fee 5%</span>
              <span className="text-gray-500">
                {mockPricing.t2bFee.toFixed(2)}€
              </span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-500">VAT</span>
              <span className="text-gray-500">
                {mockPricing.vat.toFixed(2)}€
              </span>
            </div>
          </div>
        </Transition>
        {total && (
          <div
            className="flex pb-4 justify-between w-full font-bold cursor-pointer"
            onClick={toggleExpanded}
          >
            <span>Total</span>
            <div className="flex items-center space-x-2">
              {isExpanded ? (
                <ChevronUpIcon className="w-4 h-4 text-yellow-500" />
              ) : (
                <ChevronDownIcon className="w-4 h-4 text-yellow-500" />
              )}
              <span>{total.toFixed(2)}€</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col items-center p-6 bg-white rounded-3xl shadow-xl ${isSticky ? 'sticky top-[88px] z-20' : ''}`}
    >
      {!isBrand ? (
        <h1 className="text-lg font-semibold mb-6 text-gray-400">
          Some confirmed brands at this event
        </h1>
      ) : (
        <h3 className="text-lg font-semibold mb-6">
          from {lowestPrice}€ /place
        </h3>
      )}
      {!isBrand ? (
        <div className="flex flex-row items-center justify-start w-full space-x-4">
          {BRAND_LIST.slice(0, 4).map((brand, index) => (
            <div key={index}>
              <img
                src={brand.image}
                alt={brand.name}
                className="w-14 h-14 rounded-full object-cover"
              />
            </div>
          ))}
          <PlusIcon className="w-6 h-6 text-gray-500" />
        </div>
      ) : (
        <div className="flex flex-col items-stretch w-full space-y-4">
          <button
            className="relative flex justify-center items-center py-2 px-4 border-2 border-yellow-03 text-gray-600 rounded-full transition-colors duration-300 ease-in-out hover:bg-yellow-03 hover:text-white focus:outline-none"
            onClick={() => action('plan')}
          >
            <span>Pick a place plan</span>
            <RectangleGroupIcon className="absolute right-4 w-6 h-6 " />
          </button>
          <div className="text-center text-yellow-500">or</div>
          <button
            className="relative flex justify-center items-center py-2 px-4 border-2 border-yellow-03 text-gray-600 rounded-full transition-colors duration-300 ease-in-out hover:bg-yellow-03 hover:text-white focus:outline-none"
            onClick={() => action('floorplan')}
          >
            <span>Select on floorplan</span>
            <Square3Stack3DIcon className="absolute right-4 w-6 h-6 " />
          </button>
        </div>
      )}
      {renderPricing()}
      {!isBrand ? (
        <button
          className="shadow-lg py-2 mt-8 px-8 bg-yellow-03 text-white text-xl rounded-full transition-colors duration-300 ease-in-out hover:bg-yellow-02 focus:outline-none"
          onClick={showLoginModal}
        >
          Login as Brand
        </button>
      ) : (
        <div>
          <button
            className="shadow-lg py-4 mt-4 px-4 bg-yellow-03 text-white text-xl rounded-full transition-colors duration-300 ease-in-out hover:bg-yellow-02 focus:outline-none"
            onClick={bookingAction}
          >
            Book a place now
          </button>
          <p className="text-xs text-center text-gray-600">
            You won’t be charged yet.
          </p>
        </div>
      )}
    </div>
  );
};

BookingBubble.propTypes = {
  price: PropTypes.number,
  action: PropTypes.func.isRequired,
  isSticky: PropTypes.bool,
  isBrand: PropTypes.bool,
  selectedPlace: PropTypes.object,
  lowestPrice: PropTypes.number,
};

export default BookingBubble;
