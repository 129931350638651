import React from 'react';
import PropTypes from 'prop-types';

const SpaceRow = ({ space, active, disabled }) => {
  return (
    <div
      className={`flex flex-row rounded-3xl h-40 justify-between p-4 px-8 my-2 ${active ? 'bg-gray-400' : 'bg-white'}`}
    >
      <div className="w-1/5 flex flex-col items-start justify-between">
        <div className="flex flex-col items-start">
          <h1 className={`text-3xl font-bold ${active ? 'text-white' : ''}`}>
            {space.name}
          </h1>
          <h2
            className={`text-xl font-bold text-gray-500 ${active ? 'text-white' : ''}`}
          >
            {space.size}
          </h2>
        </div>
        <p
          className={`text-xs ${active ? 'text-green-300' : 'text-green-500'}`}
        >
          {space.quantity} available
        </p>
      </div>
      <div className="w-3/5 flex items-center mx-4">
        <p className={`text-s ${active ? 'text-white' : 'text-gray-400'}`}>
          {space.description}
        </p>
      </div>
      <div
        className={`w-1/5 flex flex-col justify-center items-end ${active ? 'text-yellow-400' : 'text-yellow-03'}`}
      >
        <span className="text-4xl font-bold">{space.price}€</span>
        <span className={`text-xs ${active ? 'text-white' : 'text-gray-400'}`}>
          + Taxes
        </span>
      </div>
    </div>
  );
};

SpaceRow.propTypes = {
  space: PropTypes.object.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SpaceRow;
