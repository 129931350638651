import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { getPromoter } from '../../redux/actions/promoter';
import { getBrand } from '../../redux/actions/brand';
import { getUnreadNotificationsCount } from '../../redux/actions/notifications';

import Sidebar from './Sidebar';
import PromoterInfo from './PromoterInfo';
import BrandInfo from './BrandInfo';
import Notifications from './Notifications';
import Messages from './Messages';
import MyEvents from './MyEvents';
import Media from './Media';
import PaymentDetails from './PaymentDetails';
import Preferences from './Preferences';
import Analytics from './Analytics';
import SecurityPrivacy from './SecurityPrivacy';
import Help from './Help';
import BoardHeader from './Boardheader';

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState('info');

  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.auth);

  const promoter = useSelector((state) => state.promoter?.promoter || null);
  const brand = useSelector((state) => state.brand?.brand || null);

  const loading = useSelector(
    (state) => state.promoter?.loading || state.brand?.loading
  );
  const notificationCount = useSelector(
    (state) => state.notifications.unreadCount || 0
  );

  useEffect(() => {
    if (!loading) {
      if (user.role === 'promoter' && !promoter) {
        dispatch(getPromoter(user.userId));
      } else if (user.role === 'brand' && !brand) {
        dispatch(getBrand(user.userId));
      }
    }
  }, [dispatch, user.role, user.userId, promoter, brand, loading]);

  useEffect(() => {
    if (isAuthenticated && notificationCount === 0) {
      dispatch(getUnreadNotificationsCount());
    }
  }, [dispatch, notificationCount, isAuthenticated]);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const userInfo = user.role === 'promoter' ? promoter : brand;

  const renderSection = useMemo(() => {
    switch (activeSection) {
      case 'notifications':
        return <Notifications />;
      case 'messages':
        return <Messages />;
      case 'myEvents':
        return <MyEvents user={user.role} />;
      case 'media':
        return <Media />;
      case 'paymentDetails':
        return <PaymentDetails />;
      case 'preferences':
        return <Preferences />;
      case 'analytics':
        return <Analytics />;
      case 'securityPrivacy':
        return <SecurityPrivacy />;
      case 'help':
        return <Help />;

      default:
        return user.role === 'brand' ? <BrandInfo /> : <PromoterInfo />;
    }
  }, [activeSection, user]);

  if (loading || !userInfo) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <ClipLoader color="#FFBD59" loading={loading} size={40} />
      </div>
    );
  }

  return (
    <div className="min-h-screen p-4 flex items-start">
      <Sidebar
        user={user.role}
        onSectionChange={handleSectionChange}
        activeSection={activeSection}
        notificationCount={notificationCount}
      />
      <div className="flex-1 max-w-7xl pr-6 rounded-r-3xl">
        <BoardHeader
          info={userInfo}
          section={activeSection}
          userType={user.role}
        />
        {renderSection}
      </div>
    </div>
  );
};

export default Dashboard;
