import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  Transition,
} from '@headlessui/react';
import {
  CheckBadgeIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  Square3Stack3DIcon,
  ChevronDownIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/react/24/outline'; // Import the icon you want to use

import DatePicker from 'components/Datepicker';

// Dummy categories data
const categories = ['Fashion', 'Music', 'Art', 'Technology'];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EventSearchBar = ({
  small,
  onSearch,
  currentPage,
  currentSearch,
  onChangeSearch,
}) => {
  const [selectedLocation, setSelectedLocation] = useState('');
  const [query, setQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDates, setSelectedDates] = useState({ from: '', to: '' });
  const [openFilters, setOpenFilters] = useState(false);

  const locations = ['Lisboa', 'Porto', 'Coimbra', 'Faro'].filter((location) =>
    location.toLowerCase().includes(query.toLowerCase())
  );

  const handleDateChange = (dates) => {
    setSelectedDates(dates);
  };

  return (
    <div
      className={`flex space-y-4 p-2 w-[670px] rounded-full ${currentSearch === 'events' ? 'bg-yellow-03' : 'bg-black-01'}`}
      style={{ maxWidth: '100%' }}
    >
      <div className="flex items-center w-full space-x-2">
        <Combobox
          as="div"
          value={selectedCategories}
          onChange={setSelectedCategories}
          className="flex-1"
        >
          <div className="relative w-full">
            <ComboboxInput
              className={`w-full rounded-full border border-gray-300 bg-white py-3 text-left text-lg shadow-sm focus:outline-none sm:text-sm ${small ? 'text-xs pl-6' : 'text-lg pr-10 pl-8'}`}
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(category) => category}
              placeholder="What"
            />
            <span
              className={`absolute inset-y-0 left-0 flex items-center ${small ? 'pl-1' : 'pl-3'}`}
            >
              <Square3Stack3DIcon className="h-5 w-5 text-gray-400" />
            </span>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ComboboxOption className="absolute z-10 mt-1 max-h-60 overflow-auto w-auto bg-white rounded-lg py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {categories.map((category, idx) => (
                  <ComboboxOption
                    key={idx}
                    value={category}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {category}
                        </span>
                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckBadgeIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </>
                    )}
                  </ComboboxOption>
                ))}
              </ComboboxOption>
            </Transition>
          </div>
        </Combobox>

        {currentSearch === 'events' && !small && (
          <DatePicker onDateChange={handleDateChange} />
        )}

        <Combobox
          as="div"
          value={selectedLocation}
          onChange={setSelectedLocation}
          className="flex-1"
        >
          <div className="relative w-full">
            <ComboboxInput
              className={`w-full rounded-full border border-gray-300 bg-white py-3 text-left text-lg shadow-sm focus:outline-none sm:text-sm ${small ? 'text-xs pl-6' : 'text-lg pr-10 pl-8'}`}
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(location) => location}
              placeholder="Where"
            />
            <span
              className={`absolute inset-y-0 left-0 flex items-center ${small ? 'pl-1' : 'pl-3'}`}
            >
              <MapPinIcon className="h-5 w-5 text-gray-400" />
            </span>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ComboboxOption className="absolute z-10 mt-1 max-h-60 overflow-auto w-auto bg-white rounded-lg py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {locations.map((location, idx) => (
                  <ComboboxOption
                    key={idx}
                    value={location}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {location}
                        </span>
                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckBadgeIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </>
                    )}
                  </ComboboxOption>
                ))}
              </ComboboxOption>
            </Transition>
          </div>
        </Combobox>

        {/* Filter Button */}
        <button
          className="p-2 rounded-full text-gray-400 hover:text-gray-600"
          onClick={() => setOpenFilters(false)}
        >
          <AdjustmentsHorizontalIcon className="h-6 w-6 text-white" />
        </button>

        <button
          className="p-2 rounded-full text-white"
          onClick={() =>
            onSearch({ selectedLocation, selectedCategories, selectedDates })
          }
        >
          <MagnifyingGlassIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

EventSearchBar.propTypes = {
  small: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  currentPage: PropTypes.string,
  currentSearch: PropTypes.string,
  onChangeSearch: PropTypes.func,
};

export default EventSearchBar;
