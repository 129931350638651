// EventDetail.js
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MOCK_PROMOTER,
  MOCK_SPACES,
  MOCK_AMENITIES,
  MOCK_BRANDS,
} from '../../mockData';
import { ClipLoader } from 'react-spinners';
import { getEvent } from '../../redux/actions/events';
import { getSpacesForEvent } from '../../redux/actions/spaces';
import { createBooking } from '../../redux/actions/bookings';
import { PlusIcon, PhotoIcon } from '@heroicons/react/24/outline';
import {
  GoogleMap,
  MarkerF,
  InfoWindowF,
  useJsApiLoader,
} from '@react-google-maps/api';

import { getLowestPrice } from 'utils/getLowestSpacePrice';
import { useToast } from '../../context/toastContext';
import { getMacroName, getMicroName } from 'utils/getCategoryName';
import geocodeLocation from 'utils/getGeocodeLocation';

import Rating from 'components/Rating';
import ModalDialog from 'components/DialogModal';
import ProgressBar from 'components/ProgressBar';
import CategoryIcon from 'components/CategoryIcon';
import CategoryAccordion from 'components/CategoryAccordion';
import BookingBubble from 'components/BookingBubble';
import SpaceCardCarousel from 'components/SpaceCardCarousel';
import BrandImageCarousel from 'components/BrandImageCarousel';
import SpaceModal from 'components/SpaceModal';
import SpaceRowList from 'components/SpaceRowList';
import BrandImage from 'components/BrandImage';
import BookingConfirmationModal from 'components/BookingConfirmationModal';

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const EventDetail = () => {
  const dispatch = useDispatch();
  const addToast = useToast();
  const navigate = useNavigate();

  const [locationCoords, setLocationCoords] = useState(null);
  const [activeMarker, setActiveMarker] = useState(false);
  const [option, setOption] = useState(null);
  const [showSpaceModal, setShowSpaceModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modalContent, setModalContent] = useState({ content: '', title: '' });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isBookingModalOpen, setBookingModalOpen] = useState(false);
  const [bookingId, setBookingId] = useState(null);

  const { eventId } = useParams();
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  console.log('🚀🚀 ~  EventDetail ~  user:', user);

  const { event, loading, error } = useSelector(
    (state) => state.events.eventDetails || {}
  );
  console.log('event', event);
  const { spaces } = useSelector((state) => state.spaces);

  const lowestPrice = spaces ? getLowestPrice(spaces) : null;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
  });

  useEffect(() => {
    dispatch(getEvent(eventId));
  }, [dispatch, eventId]);

  useEffect(() => {
    if (event) {
      dispatch(getSpacesForEvent(event.id));
    }
  }, [event, dispatch]);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (event?.location) {
        const coords = await geocodeLocation(event.location);
        if (coords) {
          setLocationCoords(coords);
        } else {
          console.error(
            'Failed to fetch coordinates for location:',
            event.location
          );
        }
      }
    };

    fetchCoordinates();
  }, [event]);
  console.log('event', event);
  const isEventActive = () => {
    if (!event || !event.date) return false;

    const { to } = event.date;
    const now = moment();

    const endDate = moment(to);

    return now.isBefore(endDate);
  };
  console.log('isEventActive()', isEventActive());
  const handleShowMoreModal = (content, title) => {
    setModalContent({ content: content, title: title });
    setShowInfoModal(true);
  };

  const handleCloseMoreModal = () => {
    setShowInfoModal(false);
    setModalContent(null);
  };

  const handleSpaceModelClose = () => {
    setShowSpaceModal(false);
  };

  const handleBubbleAction = (selectedOption) => {
    setOption(selectedOption);
    setShowSpaceModal(true);
  };

  const handlePlaceSelection = (place) => {
    setSelectedPlace(place);
  };

  // Booking Confirmation Modal
  const handleBookingModalOpen = () => {
    if (!selectedPlace) {
      addToast('Please select a place first.', 'error');
      return;
    }
    setBookingModalOpen(true);
  };

  const handleBookingModalClose = () => {
    setBookingModalOpen(false);
  };

  const handleBookingConfirmation = () => {
    if (!selectedPlace) {
      addToast('No place selected for booking.', 'error');
      return;
    }

    const bookingData = {
      eventId: selectedPlace.eventId,
      brandId: user.userId,
      spaceId: selectedPlace.id,
      totalAmount: selectedPlace.price,
      paymentStatus: 'Pending',
    };

    dispatch(createBooking(bookingData))
      .then((response) => {
        const { id } = response;
        setBookingModalOpen(false);
        navigate(`/booking-status/${id}`, {
          state: {
            user: user,
            event: event,
            selectedPlace: selectedPlace,
          },
        });
      })
      .catch(() => {
        addToast('Failed to create booking. Please try again.', 'error');
      });
  };

  const renderCategoryCapacity = () => {
    return (
      <div className="flex flex-row items-end mt-2">
        {event.categoriesAllowed &&
          event.categoriesAllowed.map((cat, idx) => (
            <CategoryIcon
              key={idx}
              macro={cat.macroCategory}
              className="w-7 h-7 ml-1"
            />
          ))}
        <ProgressBar
          containerStyle="ml-4"
          current={Math.random(100)}
          total={100}
        />
      </div>
    );
  };

  const renderHeaderInfo = () => {
    const { from, to } = event?.date;

    const formattedFrom = moment(from).format(' D MMM');
    const formattedTo = moment(to).format('D MMM');

    return (
      <div className="flex justify-between items-center py-4">
        <div className="flex items-center">
          <div className="rounded-2xl bg-white p-3 shadow-xl">
            <img
              src={event?.media}
              alt={event.name}
              className="h-32 w-32 object-cover"
            />
          </div>
          <div className="flex flex-col ml-4">
            <h1 className="text-2xl font-bold">{event.name}</h1>

            <p className="text-gray-400 text-sm mb-2">{event.location}</p>

            {event.ranting && <Rating rating={event.rating} />}
            <div className="mt-4">
              <span className="px-6 py-1 rounded-2xl bg-gray-600 text-white text-sm font-bold">
                {formattedFrom} - {formattedTo}
              </span>
            </div>
            {renderCategoryCapacity()}
          </div>
        </div>
        {isAuthenticated && (
          <div className="flex flex-col items-end">
            <img
              src={MOCK_PROMOTER.avatar}
              alt={MOCK_PROMOTER.name}
              className="h-12 w-12 rounded-full object-cover"
            />
            <p className="mt-2">{MOCK_PROMOTER.name}</p>
            <p className="text-sm text-gray-500 mt-1 cursor-pointer">
              See 10 other events
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderImageSection = () => {
    return (
      <div className=" w-full h-[300px] flex justify-center items-center border border-gray-300 mb-4">
        <PhotoIcon className="h-10 w-10 text-gray-300" />
      </div>
    );
  };

  const renderSection = ({ title, content, moreButton, moreContent }, idx) => {
    return (
      <div
        key={idx}
        className={`flex flex-col mr-20 mt-6 border-b-2 border-gray-300 ${moreButton ? '' : 'pb-6'}`}
      >
        <h2 className="mb-6 text-2xl text-yellow-500 font-bold">{title}</h2>
        <div>{content}</div>
        {moreButton && (
          <div
            onClick={() => handleShowMoreModal(moreContent, title)}
            className="cursor-pointer underline text-gray-800 text-md mt-4 pb-6 flex justify-end"
          >
            {moreButton}
            <PlusIcon className="w-5 h-5 ml-2" />
          </div>
        )}
      </div>
    );
  };

  const renderEventDate = () => {
    if (!event || !event.date) {
      return null;
    }
    const { from, to, closingHours, openingHours } = event.date;

    const formattedFrom = moment(from).format('MMMM D');
    const formattedTo = moment(to).format('MMMM D');

    return (
      <div className="flex flex-col items-center">
        <div className="flex flex-row mb-2">
          <p className="text-gray-800 text-md underline">{formattedFrom}: </p>
          <p className="text-md text-gray-600 ml-2">
            {openingHours} - {closingHours}
          </p>
        </div>
        <div className="flex flex-row">
          <p className="text-gray-800 text-md underline">{formattedTo}: </p>
          <p className="text-md text-gray-600 ml-2">
            {openingHours} - {closingHours}
          </p>
        </div>
      </div>
    );
  };

  const renderMap = () => {
    const mapContainerStyle = {
      width: '100%',
      height: '300px',
      borderWidth: '1px',
      borderColor: '#D3D3D3',
      borderRadius: '20px',
    };

    const options = {
      disableDefaultUI: true,
      zoomControl: false,
      streetViewControl: false,
    };
    const boxOptions = {
      enableEventPropagation: false,
    };

    const onLoad = (infoBox) => {
      console.log('infoBox: ', infoBox);
    };

    if (!isLoaded) {
      return <div>Loading...</div>;
    }

    if (!locationCoords) {
      return (
        <div className="flex flex-col items-center justify-center ">
          <div className="flex items-center space-x-4">
            <svg
              className="w-9 h-9 text-gray-400 animate-bounce"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 2c4.418 0 8 3.582 8 8 0 6.627-8 12-8 12S4 16.627 4 10c0-4.418 3.582-8 8-8z"
              ></path>
              <circle cx="12" cy="10" r="3" />
            </svg>
            <div>
              <h3 className="text-md font-semibold text-gray-600">
                Location Not Available
              </h3>
              <p className="text-gray-500 text-xs">
                We couldn’t fetch the event location at this time.
              </p>
            </div>
          </div>
        </div>
      );
    }

    //TODO: replace marker image with real event image
    const MOCK_LOGO =
      'https://logosmarcas.net/wp-content/uploads/2020/04/Nike-Logo.png';
    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={locationCoords}
        zoom={15}
        options={options}
      >
        <MarkerF
          icon={{
            url: MOCK_LOGO,
            scaledSize: new window.google.maps.Size(40, 40),
          }}
          onMouseEnter={() => setActiveMarker(true)}
          onMouseLeave={() => setActiveMarker(false)}
          position={locationCoords}
          onClick={() => setActiveMarker(!activeMarker)}
        >
          {activeMarker && (
            <InfoWindowF
              onLoad={onLoad}
              options={boxOptions}
              position={locationCoords}
            >
              <div
                style={{
                  backgroundColor: 'yellow',
                  opacity: 0.75,
                  padding: 12,
                }}
              >
                <div style={{ fontSize: 16, fontColor: `#08233B` }}>
                  Hello, World!
                </div>
              </div>
            </InfoWindowF>
          )}
        </MarkerF>
      </GoogleMap>
    );
  };

  const renderCategories = () => {
    return (
      <div className="flex flex-col pl-8">
        {event?.categoriesAllowed &&
          event.categoriesAllowed.map((cat, idx) => (
            <div key={idx} className="flex flex-row items-center mt-2">
              <CategoryIcon macro={cat.macroCategory} className="w-8 h-8" />
              <p className="text-xl ml-2">{getMacroName(cat.macroCategory)}</p>
              {cat.microCategory.map((micro, microIdx) => (
                <p key={microIdx}>{getMicroName(micro)}</p>
              ))}
            </div>
          ))}
      </div>
    );
  };

  const renderAmenities = (amenities, isModal) => {
    if (!event || !event.utilities) {
      return <div>No amenities available.</div>;
    }

    const { utilities } = event;
    const activeAmenities = utilities.filter((amenity) =>
      amenities?.includes(amenity)
    );
    const inactiveAmenities = MOCK_AMENITIES.filter(
      (amenity) => !amenities?.includes(amenity)
    );

    return (
      <div
        className={`relative ${isModal ? 'overflow-y-auto' : 'max-h-96 overflow-hidden'}`}
      >
        <div className="grid grid-cols-2 gap-2 text-center">
          {activeAmenities?.map((amenity, index) => (
            <div key={index} className="flex justify-center text-gray-800">
              <span className="inline-block py-1">✓ {amenity}</span>
            </div>
          ))}
          {inactiveAmenities?.map((amenity, index) => (
            <div key={index} className="flex justify-center text-gray-400">
              <span className="inline-block py-1">{amenity}</span>
            </div>
          ))}
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-60 bg-gradient-to-t from-gray-100 to-transparent via-gray-100 opacity-80"></div>
      </div>
    );
  };

  const renderBrandGrid = (brands) => {
    return (
      <div className="grid grid-cols-3 gap-4 p-4 overflow-y-auto">
        {brands?.map((brand, index) => (
          <div key={index} className="rounded-full">
            <BrandImage isModal brandImage={brand.logo} />
          </div>
        ))}
      </div>
    );
  };

  const sections = [
    {
      title: 'About this event',
      content: <p>{event?.shortDescription}</p>,
      moreButton: 'Show More',
      moreContent: event?.longDescription,
    },
    {
      title: 'When',
      content: event?.date ? (
        renderEventDate()
      ) : (
        <div>No event date available</div>
      ),
    },
    {
      title: 'Where',
      content: renderMap(event?.location),
    },
    {
      title: 'Brand Categories',
      content: renderCategories(event?.categories),
      moreButton: 'Show subcategories',
      moreContent: <CategoryAccordion categories={event?.categoriesAllowed} />,
    },
    {
      title: 'In this event?',
      content: renderAmenities(event?.utilities),
      moreButton: 'Show More',
      moreContent: renderAmenities(event?.utilities, true),
    },
    {
      title: 'Place Plans',
      content: <SpaceCardCarousel spaces={spaces} />,
      moreButton: 'See more details',
      moreContent: <SpaceRowList spaces={spaces} />,
    },
    {
      title: 'Stands Confirmed',
      content: <BrandImageCarousel brands={MOCK_BRANDS} />,
      moreButton: 'Show full list',
      moreContent: renderBrandGrid(MOCK_BRANDS),
    },
  ];

  if (loading) {
    return <ClipLoader color="#FFFFFF" />;
  }

  if (!event || !event.date) {
    return <div>Event data is missing or still loading.</div>;
  }

  return (
    <>
      <div className="flex flex-col w-full h-full px-48 py-4">
        {renderHeaderInfo()}
        {renderImageSection()}
        <div className="flex flex-row">
          <div className="w-2/3">
            {sections?.map((section, idx) => renderSection(section, idx))}
          </div>
          {isEventActive() && (
            <div className="w-1/3">
              <BookingBubble
                action={handleBubbleAction}
                isSticky
                isBrand={user.role === 'brand'}
                selectedPlace={selectedPlace}
                lowestPrice={lowestPrice}
                bookingAction={handleBookingModalOpen}
              />

              <BookingConfirmationModal
                isOpen={isBookingModalOpen}
                onClose={handleBookingModalClose}
                onConfirm={handleBookingConfirmation}
                selectedPlace={selectedPlace}
                user={user}
                event={event}
              />
            </div>
          )}
        </div>
        <SpaceModal
          spaces={spaces}
          selectedPlace={selectedPlace}
          onPlaceChange={handlePlaceSelection}
          active={showSpaceModal}
          onClose={handleSpaceModelClose}
          setOption={setOption}
          option={option}
        />
      </div>
      {showInfoModal && (
        <ModalDialog
          isOpen={showInfoModal}
          closeModal={handleCloseMoreModal}
          title={modalContent.title}
          content={modalContent.content}
        />
      )}
    </>
  );
};

export default EventDetail;
