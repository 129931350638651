import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPromoterEvents } from '../../../redux/actions/events';
import { getBrandEvents } from '../../../redux/actions/bookings';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';

import EventCardWrapper from 'components/EventCardWrapper';

const MyEvents = ({ user }) => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const events = useSelector((state) => state.events.events);
  const eventsLoading = useSelector((state) => state.events.loading);
  const brandEvents = useSelector((state) => state.bookings.brandEvents || []);

  useEffect(() => {
    if (user === 'promoter') {
      dispatch(getPromoterEvents());
    }
    if (user === 'brand') {
      dispatch(getBrandEvents());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const now = moment();
    const upcoming = [];
    const past = [];

    const userEvents = user === 'promoter' ? events : brandEvents;

    userEvents.forEach((event) => {
      const eventEndDate = moment(event.date.from, 'YYYY-MM-DD');
      if (now.isBefore(eventEndDate)) {
        upcoming.push(event);
      } else {
        past.push(event);
      }
    });

    setUpcomingEvents(upcoming);
    setPastEvents(past);
    setLoading(eventsLoading);
  }, [events, brandEvents, eventsLoading, user]);

  const renderEvents = (userEvents) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 justify-center">
      {userEvents.map((ev) => (
        <EventCardWrapper key={ev.id} user={user} event={ev} small />
      ))}
    </div>
  );

  if (loading) {
    return (
      <div className="flex w-full justify-center items-center bg-white rounded-br-3xl py-10">
        <ClipLoader color="#FFBD59" size={30} />
      </div>
    );
  }

  return (
    <div className="w-full bg-white px-6 py-6 rounded-b-r-3xl text-gray-700 space-y-8 overflow-y-auto shadow-lg">
      <div>
        <h3 className="text-lg font-bold text-yellow-500">Upcoming Events</h3>
        {upcomingEvents.length > 0 ? (
          renderEvents(upcomingEvents)
        ) : (
          <p className="text-sm text-gray-500 mt-4">No upcoming events.</p>
        )}
      </div>
      <div>
        <h3 className="text-lg font-bold text-gray-500">Past Events</h3>
        {pastEvents.length > 0 ? (
          renderEvents(pastEvents)
        ) : (
          <p className="text-sm text-gray-500 mt-4">No past events.</p>
        )}
      </div>
    </div>
  );
};

MyEvents.propTypes = {
  user: PropTypes.string.isRequired,
};

export default MyEvents;
