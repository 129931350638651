import React from 'react';
import { MOCK_BRAND_INFO } from 'mockData';

import CategoryIcon from 'components/CategoryIcon';
import facebookIcon from '../../../assets/icons/socials/facebook_icon.svg';
import instagramIcon from '../../../assets/icons/socials/instagram_icon.svg';
import linkedinIcon from '../../../assets/icons/socials/linkedin_icon.svg';
import twitterIcon from '../../../assets/icons/socials/twitter_icon.svg';

const BrandInfo = () => {
  const brandDetails = MOCK_BRAND_INFO;

  const icons = {
    facebook: facebookIcon,
    instagram: instagramIcon,
    linkedin: linkedinIcon,
    twitter: twitterIcon,
  };

  const renderBrandDetailInfo = () => {
    return (
      <div className="flex flex-row mt-4 space-x-4 justify-between text-sm">
        <div className="flex flex-col">
          <h3 className="text-sm font-bold text-yellow-02">
            Year of Foundation
          </h3>
          <p>{brandDetails.yearOfFoundation}</p>
        </div>
        <div className="flex flex-col">
          <h3 className="text-sm font-bold text-yellow-02">NIF/NIPC</h3>
          <p>{brandDetails.nif}</p>
        </div>
        <div className="flex flex-col">
          <h3 className="text-sm font-bold text-yellow-02">
            Country of Origin
          </h3>
          <p>{brandDetails.country}</p>
        </div>
        <div className="flex flex-col">
          <h3 className="text-sm font-bold text-yellow-02">City of Origin</h3>
          <p>{brandDetails.city}</p>
        </div>
        <div className="flex flex-col">
          <h3 className="text-sm font-bold text-yellow-02">Website</h3>
          <p>{brandDetails.website}</p>
        </div>
      </div>
    );
  };

  const renderCategories = () => {
    return (
      <div className="mt-6">
        <h3 className="text-sm font-bold text-yellow-02">
          Categories and sub categories
        </h3>
        {brandDetails.categories.map((cat, idx) => (
          <div key={idx} className="flex text-sm items-center mt-2">
            <CategoryIcon macro={cat.icon} className="w-8 h-8 mr-2" />
            <span>{cat.macro}</span>
            <span className="text-sm ml-2">＞</span>
            <CategoryIcon
              macro={cat.icon}
              micro={cat.micro.icon}
              className="w-8 h-8 ml-2"
            />
            <span className="ml-2">{cat.micro.name}</span>
          </div>
        ))}
      </div>
    );
  };

  const renderKeywords = () => {
    return (
      <div className="mt-6 text-sm">
        <h3 className="text-sm font-bold text-yellow-02">Keywords</h3>
        <p>{brandDetails.keywords.join(' ; ')}</p>
      </div>
    );
  };

  const renderShortDescription = () => {
    return (
      <div className="mt-6 text-sm">
        <h3 className="text-sm font-bold text-yellow-02">Description</h3>
        <p>{brandDetails.shortDescription}</p>
      </div>
    );
  };

  const renderSocialMedia = () => {
    return (
      <div className="mt-6 text-sm">
        <h3 className="text-sm font-bold text-yellow-02">Social Media Links</h3>
        <div className="flex text-sm mt-2 flex-col space-y-3">
          {brandDetails.socialMedia.map((social, idx) => (
            <div key={idx} className="flex flex-row space-x-2 text-xs">
              <a href={social.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={icons[social.name.toLowerCase()]}
                  alt={`${social.name} icon`}
                  className="h-4 w-4"
                />
              </a>
              <p>{social.url}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderHistory = () => {
    return (
      <div className="mt-6 text-sm">
        <h3 className="text-sm font-bold text-yellow-02">History</h3>
        <p>{brandDetails.history}</p>
      </div>
    );
  };

  return (
    <div className="w-full bg-white p-6 rounded-br-3xl text-gray-600 overflow-y-auto">
      {renderBrandDetailInfo()}
      {renderCategories()}
      {renderKeywords()}
      {renderShortDescription()}
      {renderSocialMedia()}
      {renderHistory()}
    </div>
  );
};

export default BrandInfo;
