import {
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_SUCCESS,
  CREATE_BOOKING_FAIL,
  GET_BOOKINGS_REQUEST,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAIL,
  GET_BOOKING_REQUEST,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_FAIL,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAIL,
  APPROVE_BOOKING_SUCCESS,
  REJECT_BOOKING_SUCCESS,
  BOOKING_ACTION_FAIL,
  GET_BRAND_EVENTS_REQUEST,
  GET_BRAND_EVENTS_SUCCESS,
  GET_BRAND_EVENTS_FAIL,
} from '../actions/bookings';

const initialState = {
  bookings: [],
  booking: null,
  brandEvents: [],
  loading: false,
  error: null,
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BOOKING_REQUEST:
    case GET_BOOKINGS_REQUEST:
    case GET_BRAND_EVENTS_REQUEST:
      return { ...state, loading: true, error: null };

    case CREATE_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: [...state.bookings, action.payload],
        loading: false,
      };

    case GET_BOOKINGS_SUCCESS:
      return { ...state, bookings: action.payload, loading: false };

    case GET_BRAND_EVENTS_SUCCESS:
      return { ...state, brandEvents: action.payload, loading: false };

    case CREATE_BOOKING_FAIL:
    case GET_BOOKINGS_FAIL:
    case GET_BRAND_EVENTS_FAIL:
    case UPDATE_BOOKING_FAIL:
    case DELETE_BOOKING_FAIL:
    case BOOKING_ACTION_FAIL:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: state.bookings.map((booking) =>
          booking.id === action.payload.id ? action.payload : booking
        ),
        loading: false,
      };

    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: state.bookings.filter(
          (booking) => booking.id !== action.payload
        ),
        loading: false,
      };

    case APPROVE_BOOKING_SUCCESS:
    case REJECT_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: state.bookings.map((booking) =>
          booking.id === action.payload.id ? action.payload : booking
        ),
      };
    case GET_BOOKING_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_BOOKING_SUCCESS:
      return { ...state, booking: action.payload, loading: false };
    case GET_BOOKING_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default bookingReducer;
