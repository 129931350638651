import React from 'react';
import { PacmanLoader } from 'react-spinners';

const Payments = () => {
  return (
    <div className=" flex flex-1 flex-col items-center justify-center h-screen pb-40 w-full">
      <h2 className="text-2xl font-bold">Payments</h2>
      <div className="flex flex-col items-center justify-center py-8">
        <p className="text-gray-500 text-sm">
          Work in progress. Please check back later.
        </p>
      </div>
      <PacmanLoader color="#FFBD59" size={20} />
    </div>
  );
};

export default Payments;
