import React from 'react';
import PropTypes from 'prop-types';

const Sidebar = ({
  user,
  onSectionChange,
  activeSection,
  notificationCount,
}) => {
  const navigation = [
    { name: 'Main Info', section: 'info' },
    { name: 'Notifications', section: 'notifications' },
    { name: 'Messages', section: 'messages' },
    {
      name: user === 'promoter' ? 'My Events' : 'My Bookings',
      section: 'myEvents',
    },
    { name: 'Media', section: 'media' },
    { name: 'Payment Details', section: 'paymentDetails' },
    { name: 'Preferences', section: 'preferences' },
    { name: 'Analytics', section: 'analytics' },
    { name: 'Security & Privacy', section: 'securityPrivacy' },
    { name: 'Help', section: 'help' },
  ];

  return (
    <div className="flex w-full md:w-64 bg-gray-100 items-center justify-center overflow-visible">
      <div className="flex flex-col space-y-2 w-full">
        {navigation.map((item) => (
          <button
            key={item.name}
            onClick={() => onSectionChange(item.section)}
            className={`py-4 border bg-gray-300 text-sm font-bold text-gray-500 rounded-l-3xl ${
              activeSection === item.section
                ? 'bg-gray-700 text-white shadow-xl'
                : ''
            }`}
          >
            {item.name}
            {item.section === 'notifications' && notificationCount > 0 && (
              <span className="ml-4 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                {notificationCount}
              </span>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  user: PropTypes.string.isRequired,
  onSectionChange: PropTypes.func,
  activeSection: PropTypes.string,
  notificationCount: PropTypes.number,
};

export default Sidebar;
