import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { thunk } from 'redux-thunk';
import authReducer from '../reducers/auth';
import eventReducer from '../reducers/events';
import spaceReducer from '../reducers/spaces';
import notificationReducer from '../reducers/notifications';
import promoterReducer from '../reducers/promoter';
import brandReducer from '../reducers/brand';
import bookingReducer from '../reducers/bookings';

const rootReducer = combineReducers({
  auth: authReducer,
  events: eventReducer,
  spaces: spaceReducer,
  notifications: notificationReducer,
  promoter: promoterReducer,
  brand: brandReducer,
  bookings: bookingReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
