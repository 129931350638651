import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getBooking } from '../../redux/actions/bookings';
import { useDispatch, useSelector } from 'react-redux';

import { CheckCircleIcon } from '@heroicons/react/24/outline';

const BookingStatus = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { event } = location.state;
  console.log('🚀🚀 ~  BookingStatus ~  event:', event);

  const { booking } = useSelector((state) => state.bookings);
  console.log('booking', booking);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getBooking(event.bookingId));
  }, [dispatch, event.bookingId]);

  const status = booking?.status || 'Pending';

  let title = 'Your Request has been sent to the promoter.';
  let step1Styles = 'bg-yellow-02 text-white';
  let step2Styles = 'bg-gray-300 text-gray-500';
  let step3Styles = 'bg-gray-300 text-gray-500';

  if (status === 'Approved') {
    title = 'Your Request has been approved by the promoter.';
    step2Styles = 'bg-yellow-02 text-white';
  } else if (status === 'Paid') {
    title = 'Your booking is confirmed and paid.';
    step2Styles = 'bg-yellow-02 text-white';
    step3Styles = 'bg-yellow-02 text-white';
  }

  return (
    <div className="flex flex-col w-full h-screen">
      <div className="space-y-8 p-20">
        <div className="flex justify-center items-center space-x-4">
          <p className="font-bold text-md text-gray-400">
            {user?.username || 'Brand Name'}
          </p>
          <p className="font-bold text-yellow-02 text-xl">{'→'}</p>
          <p className="font-bold text-md text-gray-400">
            {event?.name || 'Event Name'}
          </p>
          <p className="font-bold text-yellow-02 text-xl">{'→'}</p>
          <p className="font-bold text-md text-gray-400">
            {booking?.name || 'Place'}
          </p>
        </div>

        <div className="flex justify-center items-center">
          <h2 className="text-3xl font-bold text-yellow-02 text-center">
            {title}
          </h2>
          <CheckCircleIcon className="ml-2 w-12 h-12 text-yellow-02" />
        </div>

        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex w-1/6 flex-col items-center">
            <div
              className={`w-24 h-24 flex items-center justify-center rounded-full ${step1Styles}`}
            >
              <span className="font-bold text-3xl">1</span>
            </div>
            <p className="text-center font-semibold mt-2 text-yellow-02">
              Booking request sent.
            </p>
          </div>

          <div className="w-1/4 border-t-2 border-gray-300" />

          <div className="flex w-1/6 flex-col items-center">
            <div
              className={`w-24 h-24 flex items-center justify-center rounded-full ${step2Styles}`}
            >
              <span className="font-bold text-3xl">2</span>
            </div>
            <p
              className={`text-center font-semibold mt-2 ${status === 'Approved' || status === 'Paid' ? 'text-yellow-02' : 'text-gray-400'}`}
            >
              Promoter approved request.
            </p>
          </div>

          <div className="w-1/4 border-t-2 border-gray-300" />

          <div className="flex w-1/6 flex-col items-center">
            <div
              className={`w-24 h-24 flex items-center justify-center rounded-full ${step3Styles}`}
            >
              <span className="font-bold text-3xl">3</span>
            </div>
            <p
              className={`text-center font-semibold mt-2 ${status === 'Paid' ? 'text-yellow-02' : 'text-gray-400'}`}
            >
              Booking paid and booking is confirmed.
            </p>
          </div>
        </div>

        <div className="flex justify-center py-12">
          <a
            href="/"
            className="text-gray-600 underline text-sm font-semibold hover:text-yellow-400"
          >
            Return to homepage.
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookingStatus;
