import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { yupResolver } from '@hookform/resolvers/yup';
import { Transition } from '@headlessui/react';
import { eventSchema } from '../../utils/validationSchema';
import { useToast } from '../../context/toastContext';
import { useDispatch } from 'react-redux';
import { createEvent } from '../../redux/actions/events';
import { createSpaces } from '../../redux/actions/spaces';

import { Checkbox, Textarea } from '@headlessui/react';
import {
  CheckIcon,
  PlusCircleIcon,
  PlusIcon,
  MinusIcon,
  GlobeAltIcon,
} from '@heroicons/react/24/solid';
import DatePicker from 'components/Datepicker';
import CategoryIcon from 'components/CategoryIcon';
import Stepper from './Stepper';

import { categories } from 'utils/categories';

import facebookIcon from '../../assets/icons/socials/facebook_icon.svg';
import instagramIcon from '../../assets/icons/socials/instagram_icon.svg';
import linkedinIcon from '../../assets/icons/socials/linkedin_icon.svg';
import twitterIcon from '../../assets/icons/socials/twitter_icon.svg';
import { MOCK_AMENITIES } from 'mockData';

import Step4 from './Steps/Step4';

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const EventCreation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMicroCategories, setSelectedMicroCategories] = useState([]);

  const addToast = useToast();

  const methods = useForm({
    resolver: yupResolver(eventSchema[step - 1]),
    mode: 'onTouched',
    defaultValues: {
      eventIcon: null,
      mainImage: null,
      morePictures: [],
      regulatoryDocument: null,
      location: '',
      utilities: [],
      locationCoords: null,
      date: {
        from: '',
        to: '',
        openingHours: '',
        closingHours: '',
      },
    },
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const steps = [
    { label: 'Event Details' },
    { label: 'Media & Amenities' },
    { label: 'Space Creation' },
    { label: 'Floor Planning' },
    { label: 'Summary' },
  ];

  const onSubmit = async (data) => {
    console.log('🚀🚀 ~  onSubmit ~  data:', data);
    setLoading(true);
    try {
      // Prepare the event data (excluding spaces)
      const eventData = {
        ...data,
        categoriesAllowed: selectedCategories.map((macro) => ({
          macroCategory: macro,
          microCategory: selectedMicroCategories
            .filter((micro) => micro.macroId === macro)
            .map((micro) => micro.id),
        })),
      };

      delete eventData.openingHours;
      delete eventData.closingHours;

      const eventResponse = await dispatch(createEvent(eventData));

      if (eventResponse && eventResponse.payload && eventResponse.payload.id) {
        const eventId = eventResponse.payload.id;

        const spacesData = data.spaces.map((space) => ({
          ...space,
          eventId,
        }));

        await dispatch(createSpaces(spacesData));
        addToast('Event created successfully!', 'success');
      } else {
        console.error('Failed to create the event');
      }
    } catch (error) {
      console.error('Error creating event or spaces:', error.message);
    } finally {
      navigate('/dashboard');
      setLoading(false);
    }
  };

  useEffect(() => {
    const transformedCategories = selectedCategories.map((macroCategoryId) => {
      const microCategoryIds = selectedMicroCategories
        .filter((micro) => micro.macroId === macroCategoryId)
        .map((micro) => micro.id);

      return {
        macroCategoryId,
        microCategoryIds,
      };
    });

    methods.setValue('categoriesAllowed', transformedCategories);
  }, [selectedCategories, selectedMicroCategories, methods]);

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  const handleStepChange = async (newStep) => {
    const isStepValid = await methods.trigger();

    if (isStepValid) {
      setIsTransitioning(true);
      setTimeout(() => {
        setStep(newStep);
        setIsTransitioning(false);
      }, 300);
    } else {
      console.error('Validation errors in the current step.');
    }
  };

  const Step1 = () => {
    const {
      register,
      formState: { errors },
      setValue,
      getValues,
    } = useFormContext();
    const [showSegmentedEvent, setShowSegmentedEvent] = useState(false);
    const [dateRange, setDateRange] = useState({
      from: new Date(),
      to: new Date(),
    });
    const [availableMicroCategories, setAvailableMicroCategories] = useState(
      []
    );

    const handleSegmentedEventClick = () => {
      setShowSegmentedEvent(!showSegmentedEvent);
    };

    const handleDateChange = (date) => {
      setDateRange(date);
      const currentValues = getValues();
      setValue('date', {
        ...date,
        openingHours: currentValues.openingHours,
        closingHours: currentValues.closingHours,
      });
    };

    const handleOpeningHoursChange = (event) => {
      const openingHours = event.target.value;
      const currentValues = getValues();
      setValue('openingHours', openingHours);
      setValue('date', { ...currentValues.date, openingHours });
    };

    const handleClosingHoursChange = (event) => {
      const closingHours = event.target.value;
      const currentValues = getValues();
      setValue('closingHours', closingHours);
      setValue('date', { ...currentValues.date, closingHours });
    };

    const handleMacroCategoryChange = (categoryId) => {
      setSelectedCategories((prevSelectedCategories) =>
        prevSelectedCategories.includes(categoryId)
          ? prevSelectedCategories.filter((id) => id !== categoryId)
          : [...prevSelectedCategories, categoryId]
      );
    };

    const handleMicroCategoryChange = (microCategory) => {
      setSelectedMicroCategories((prevSelectedMicroCategories) =>
        prevSelectedMicroCategories.some(
          (category) => category.id === microCategory.id
        )
          ? prevSelectedMicroCategories.filter(
              (category) => category.id !== microCategory.id
            )
          : [...prevSelectedMicroCategories, microCategory]
      );
    };

    useEffect(() => {
      const microCategories = [];
      selectedCategories.forEach((categoryId) => {
        const category = categories.find((cat) => cat.id === categoryId);
        if (category) {
          category.micro.forEach((micro) => {
            microCategories.push({
              ...micro,
              macro: category.icon,
              macroId: categoryId,
            });
          });
        }
      });
      setAvailableMicroCategories(microCategories);
    }, [selectedCategories]);

    return (
      <div className="h-full overflow-y-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Event Name
            </label>
            <input
              {...register('name')}
              className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm outline-none"
              placeholder="Enter event name"
            />
            {errors.name && (
              <p className="text-red-500 text-xs">{errors.name.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Location
            </label>
            <GooglePlacesAutocomplete
              apiKey={API_KEY}
              selectProps={{
                value: methods.getValues('location')
                  ? {
                      label: methods.getValues('location'),
                      value: methods.getValues('location'),
                    }
                  : null,
                onChange: (place) => {
                  const locationDescription = place.value.description;
                  setLocation(locationDescription);
                  setValue('location', locationDescription);
                },
                placeholder: location ? location : 'Enter location',
                className: 'text-xs',
              }}
            />
            {!location && errors.location && (
              <p className="text-red-500 text-xs">{errors.location.message}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Date</label>
            <DatePicker onDateChange={handleDateChange} />
            {errors.date && (
              <p className="text-red-500 text-xs">A valid date is required</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Opening Hours
            </label>
            <input
              type="time"
              {...register('date.openingHours')}
              onChange={handleOpeningHoursChange}
              className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm outline-none"
            />
            {errors.openingHours && (
              <p className="text-red-500 text-xs">
                {errors.openingHours.message}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Closing Hours
            </label>
            <input
              type="time"
              {...register('date.closingHours')}
              onChange={handleClosingHoursChange}
              className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm outline-none"
            />
            {errors.closingHours && (
              <p className="text-red-500 text-xs">
                {errors.closingHours.message}
              </p>
            )}
          </div>
        </div>

        <div className="mt-6 flex items-center space-x-3">
          <Checkbox
            checked={showSegmentedEvent}
            onChange={handleSegmentedEventClick}
            className="h-6 w-6 text-yellow-500 rounded border border-gray-300 outline-none"
          >
            {showSegmentedEvent && (
              <CheckIcon className="text-yellow-03 font-bold border border-yellow-03" />
            )}
          </Checkbox>
          <label className="text-sm font-medium text-gray-700">
            Segmented your Event
          </label>
        </div>

        {showSegmentedEvent && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Segment Date
              </label>
              <input
                type="date"
                {...register('segmentDate')}
                className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm outline-none"
              />
              {errors.segmentDate && (
                <p className="text-red-500 text-xs">
                  {errors.segmentDate.message}
                </p>
              )}
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Segment Opening Hours
              </label>
              <input
                type="time"
                {...register('segmentOpeningHours')}
                className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm outline-none"
              />
              {errors.segmentOpeningHours && (
                <p className="text-red-500 text-xs">
                  {errors.segmentOpeningHours.message}
                </p>
              )}
            </div>

            <div className="space-y-2">
              <label className="text-sm font-medium text-gray-700">
                Segment Closing Hours
              </label>
              <input
                type="time"
                {...register('segmentClosingHours')}
                className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm outline-none"
              />
              {errors.segmentClosingHours && (
                <p className="text-red-500 text-xs">
                  {errors.segmentClosingHours.message}
                </p>
              )}
            </div>
          </div>
        )}

        <div className="mt-6">
          <label className="text-sm font-medium text-gray-700">
            Categories Allowed
          </label>
          <div className="flex space-x-4 mt-2 border border-gray-300 p-6 rounded-lg">
            <div className="w-1/2 space-y-4">
              {categories.map((category) => (
                <div className="flex items-center space-x-2" key={category.id}>
                  <Checkbox
                    checked={selectedCategories.includes(category.id)}
                    onChange={() => handleMacroCategoryChange(category.id)}
                    className="h-6 w-6 text-yellow-500 rounded border border-gray-300 focus:ring-yellow-500"
                  >
                    {selectedCategories.includes(category.id) && (
                      <CheckIcon className="text-yellow-03 font-bold border border-yellow-03" />
                    )}
                  </Checkbox>
                  <CategoryIcon macro={category.icon} className="w-6 h-6" />
                  <span className="text-sm">{category.macro}</span>
                </div>
              ))}
            </div>

            <div className="w-1/2 space-y-4 overflow-y-auto max-h-48">
              {availableMicroCategories.length > 0 ? (
                availableMicroCategories.map((microCategory) => (
                  <div
                    className="flex items-center space-x-2"
                    key={microCategory.id}
                  >
                    <Checkbox
                      checked={selectedMicroCategories.some(
                        (category) => category.id === microCategory.id
                      )}
                      onChange={() => handleMicroCategoryChange(microCategory)}
                      className="h-6 w-6 text-yellow-500 rounded border border-gray-300 focus:ring-yellow-500"
                    >
                      {selectedMicroCategories.some(
                        (category) => category.id === microCategory.id
                      ) && (
                        <CheckIcon className="text-yellow-03 font-bold border border-yellow-03" />
                      )}
                    </Checkbox>
                    <CategoryIcon
                      macro={microCategory.macro}
                      micro={microCategory.icon}
                      className="w-6 h-6"
                    />
                    <span className="text-sm">{microCategory.name}</span>
                  </div>
                ))
              ) : (
                <div className="text-gray-400 text-sm">
                  Select a macro category to see the micro categories
                </div>
              )}
            </div>
          </div>
          {errors?.categoriesAllowed && (
            <p className="text-red-500 text-xs mt-2">
              {errors.categoriesAllowed.message}
            </p>
          )}
        </div>

        <div className="mt-6 space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Short Description
            </label>
            <Textarea
              {...register('shortDescription')}
              className="w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm focus:ring-yellow-500 focus:border-yellow-500"
              placeholder="Write a short description..."
              rows={1}
            />
            {errors.shortDescription && (
              <p className="text-red-500 text-xs">
                {errors.shortDescription.message}
              </p>
            )}
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Long Description
            </label>
            <Textarea
              {...register('longDescription')}
              className="w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm focus:ring-yellow-500 focus:border-yellow-500"
              placeholder="Write a long description..."
              rows={3}
            />
            {errors.longDescription && (
              <p className="text-red-500 text-xs">
                {errors.longDescription.message}
              </p>
            )}
          </div>
        </div>

        <div className="mt-6">
          <label className="text-sm font-medium text-gray-700">Website</label>
          <div className="flex items-center space-x-2 mt-2">
            <GlobeAltIcon className="w-6 h-6 text-gray-400" />
            <input
              {...register('website')}
              className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm focus:ring-yellow-500 focus:border-yellow-500"
              placeholder="Website URL"
            />
          </div>
          {errors.website && (
            <p className="text-red-500 text-xs">{errors.website.message}</p>
          )}
        </div>

        <div className="mt-6 space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">
              Social Media Links
            </label>
            <div className="flex space-x-4">
              <div className="flex items-center space-x-2">
                <img src={facebookIcon} alt="Facebook" className="w-6 h-6" />
                <input
                  {...register('facebookUrl')}
                  className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm focus:ring-yellow-500 focus:border-yellow-500"
                  placeholder="Facebook URL"
                />
              </div>
              <div className="flex items-center space-x-2">
                <img src={twitterIcon} alt="Twitter" className="w-6 h-6" />
                <input
                  {...register('twitterUrl')}
                  className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm focus:ring-yellow-500 focus:border-yellow-500"
                  placeholder="Twitter URL"
                />
              </div>
              <div className="flex items-center space-x-2">
                <img src={instagramIcon} alt="Instagram" className="w-6 h-6" />
                <input
                  {...register('instagramUrl')}
                  className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm focus:ring-yellow-500 focus:border-yellow-500"
                  placeholder="Instagram URL"
                />
              </div>
              <div className="flex items-center space-x-2">
                <img src={linkedinIcon} alt="LinkedIn" className="w-6 h-6" />
                <input
                  {...register('linkedinUrl')}
                  className="block w-full border border-gray-300 rounded-lg shadow-sm p-2 text-sm focus:ring-yellow-500 focus:border-yellow-500"
                  placeholder="LinkedIn URL"
                />
              </div>
            </div>

            {errors.facebookUrl && (
              <p className="text-red-500 text-xs">
                {errors.facebookUrl.message}
              </p>
            )}
            {errors.twitterUrl && (
              <p className="text-red-500 text-xs">
                {errors.twitterUrl.message}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const Step2 = () => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
    const [customAmenities, setCustomAmenities] = useState([]);
    const [newAmenity, setNewAmenity] = useState('');

    const handleAddAmenity = () => {
      if (newAmenity.trim()) {
        setCustomAmenities([...customAmenities, newAmenity.trim()]);
        setNewAmenity('');
      }
    };

    const allAmenities = [...MOCK_AMENITIES, ...customAmenities];

    return (
      <div className="p-4">
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block">Event Icon (Square)</label>
            <input
              type="file"
              {...register('eventIcon')}
              className="border p-2 w-full"
            />
            {errors.eventIcon && (
              <p className="text-red-500">{errors.eventIcon.message}</p>
            )}
          </div>
          <div>
            <label className="block">Main Image (Horizontal)</label>
            <input
              type="file"
              {...register('mainImage')}
              className="border p-2 w-full"
            />
            {errors.mainImage && (
              <p className="text-red-500">{errors.mainImage.message}</p>
            )}
          </div>
          <div>
            <label className="block">More Pictures (up to 10)</label>
            <input
              type="file"
              multiple
              {...register('morePictures')}
              className="border p-2 w-full"
            />
            {errors.morePictures && (
              <p className="text-red-500">{errors.morePictures.message}</p>
            )}
          </div>
          <div>
            <label className="block">Regulatory Document</label>
            <input
              type="file"
              {...register('regulatoryDocument')}
              className="border p-2 w-full"
            />
            {errors.regulatoryDocument && (
              <p className="text-red-500">
                {errors.regulatoryDocument.message}
              </p>
            )}
          </div>
        </div>

        <h2 className="text-2xl mb-4">Amenities</h2>
        <div className="grid grid-cols-3 gap-4">
          {allAmenities.map((amenity, index) => (
            <div key={index}>
              <label className="block">
                <input
                  type="checkbox"
                  {...register('utilities')}
                  value={amenity.toLowerCase().replace(' ', '_')}
                />{' '}
                {amenity}
              </label>
            </div>
          ))}
        </div>
        {errors.utilities && (
          <p className="text-red-500">{errors.utilities.message}</p>
        )}

        <div className="mt-4">
          <label className="block">Add New</label>
          <div className="flex space-x-2">
            <input
              type="text"
              value={newAmenity}
              onChange={(e) => setNewAmenity(e.target.value)}
              className="border text-sm p-1"
            />
            <button type="button" onClick={handleAddAmenity} className="p-1">
              <PlusCircleIcon className="w-6 h-6 text-yellow-02" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const Step3 = () => {
    const {
      register,
      control,
      formState: { errors },
    } = useFormContext();
    const { fields, append, remove } = useFieldArray({
      control,
      name: 'spaces',
    });

    // Render all errors at the bottom of the form
    const renderErrorMessages = () => {
      const spaceErrors = errors.spaces
        ? Object.keys(errors.spaces).map((key) => {
            const fieldErrors = errors.spaces[key];
            return Object.keys(fieldErrors).map((fieldKey) => (
              <li key={`${key}-${fieldKey}`}>
                {`Space ${parseInt(key) + 1} - ${fieldErrors[fieldKey].message}`}
              </li>
            ));
          })
        : [];

      return (
        <ul className="text-red-500 text-sm mt-2 space-y-1">
          {spaceErrors.flat()}
          {errors.spaces?.message && <li>{errors.spaces.message}</li>}
        </ul>
      );
    };

    return (
      <div className="p-4">
        <h2 className="text-2xl mb-4">Place plans and Prices</h2>

        {/* Header */}
        <div className="grid grid-cols-8 gap-4 mb-4 items-center bg-gray-700 text-white p-2 rounded-t-md">
          <div className="col-span-2">Name</div>
          <div>Size (m²)</div>
          <div className="col-span-3">Description</div>
          <div># available</div>
          <div>Price without taxes</div>
        </div>

        {/* Dynamic fields for spaces */}
        {fields.map((item, index) => (
          <div
            key={item.id}
            className="grid grid-cols-8 gap-4 mb-4 items-center p-2 bg-white relative"
          >
            {/* Name field */}
            <div className="flex items-center col-span-2 relative">
              <button
                type="button"
                onClick={() => remove(index)}
                className="bg-red-500 text-white p-1 rounded-full absolute left-0 transform -translate-x-full"
              >
                <MinusIcon className="w-3 h-3" />
              </button>
              <input
                {...register(`spaces.${index}.name`)}
                className="border p-2 w-full outline-none ml-4"
                placeholder="Enter name"
              />
            </div>

            {/* Size field */}
            <div>
              <input
                type="number"
                {...register(`spaces.${index}.size`)}
                className="border p-2 w-full outline-none"
                placeholder="Enter size"
                min="0"
                onKeyDown={(e) => {
                  if (e.key === '-' || e.key === 'e' || e.key === 'E') {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            {/* Description field */}
            <div className="col-span-3">
              <input
                {...register(`spaces.${index}.description`)}
                className="border p-2 w-full outline-none"
                placeholder="Enter description"
              />
            </div>

            {/* Available field */}
            <div>
              <input
                type="number"
                {...register(`spaces.${index}.quantity`)}
                className="border p-2 w-full outline-none"
                placeholder="Enter available quantity"
              />
            </div>

            {/* Price field */}
            <div>
              <input
                type="number"
                {...register(`spaces.${index}.price`)}
                className="border p-2 w-full outline-none"
                placeholder="Enter price"
              />
            </div>
          </div>
        ))}

        {/* Add space button */}
        <div className="flex justify-center mt-4">
          <button
            type="button"
            onClick={() =>
              append({
                name: '',
                size: 0,
                description: '',
                quantity: 1,
                price: 0,
              })
            }
            className="bg-yellow-03 text-white p-2 rounded-full"
          >
            <PlusIcon className="w-6 h-6" />
          </button>
        </div>

        {/* Centralized error messages */}
        <div className="mt-4">
          {renderErrorMessages()}

          {/* Array-level error */}
          {errors.spaces && !fields.length && (
            <p className="text-red-500 text-sm mt-2">
              At least one space is required to proceed.
            </p>
          )}
        </div>
      </div>
    );
  };

  const renderStep4 = () => {
    return <Step4 />;
  };

  const Step5 = () => {
    const { getValues } = useFormContext();
    const values = getValues();

    return (
      <div className="p-4">
        <h2 className="text-2xl mb-4">Preview</h2>
        <div className="border p-4 mb-4">
          <h3 className="text-xl mb-2">Event Details</h3>
          <p>
            <strong>Name:</strong> {values.name}
          </p>
          <p>
            <strong>Location:</strong> {values.location}
          </p>
          <p>
            <strong>Date:</strong> From {values.date?.from?.toString()} to{' '}
            {values.date?.to?.toString()}
          </p>
          <p>
            <strong>Opening Hours:</strong> {values.openingHours}
          </p>
          <p>
            <strong>Closing Hours:</strong> {values.closingHours}
          </p>
          <p>
            <strong>Categories Allowed:</strong>{' '}
            {values.categoriesAllowed?.join(', ')}
          </p>
          <p>
            <strong>Short Description:</strong> {values.shortDescription}
          </p>
          <p>
            <strong>Long Description:</strong> {values.longDescription}
          </p>
        </div>
        <div className="border p-4 mb-4">
          <h3 className="text-xl mb-2">Spaces</h3>
          {values.spaces?.map((space, index) => (
            <div
              key={index}
              className={`${index >= 0 && index < values.spaces.length ? 'border-b' : ''}`}
            >
              <p>
                <strong>Name:</strong> {space.name}
              </p>
              <p>
                <strong>Size:</strong> {space.size} m²
              </p>
              <p>
                <strong>Description:</strong> {space.description}
              </p>
              <p>
                <strong>Availability:</strong> {space.quantity}
              </p>
              <p>
                <strong>Price:</strong> {space.price}
              </p>
            </div>
          ))}
        </div>
        <div className="border p-4 mb-4">
          <h3 className="text-xl mb-2">Floorplan</h3>
          <p>
            <strong>Create Floorplan:</strong>{' '}
            {values.createFloorplan ? 'Yes' : 'No'}
          </p>
          {values.createFloorplan && (
            <>
              <p>
                <strong>Floorplan Image:</strong> {values.floorplanImage?.name}
              </p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="pb-8 bg-gray-100 flex flex-col px-12">
      <div className="flex height-minus-120 bg-white shadow-lg rounded-lg p-8">
        <div className="w-1/5 border-r px-4">
          <div className="mb-8">
            <h1 className="text-gray-700 text-2xl font-bold">Create Event</h1>
          </div>
          <Stepper steps={steps} currentStep={step} />
        </div>
        <div className="w-4/5 pl-8 flex flex-col justify-between h-full overflow-y-auto pr-4">
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col flex-grow justify-between"
            >
              <Transition
                show={!isTransitioning}
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-0 translate-y-4"
                enterTo="transform opacity-100 translate-y-0"
                leave="transition ease-in duration-300"
                leaveFrom="transform opacity-100 translate-y-0"
                leaveTo="transform opacity-0 translate-y-4"
                className="mb-4 flex-grow"
              >
                <div>
                  {step === 1 ? (
                    <Step1 />
                  ) : step === 2 ? (
                    <Step2 />
                  ) : step === 3 ? (
                    <Step3 />
                  ) : step === 4 ? (
                    renderStep4()
                  ) : (
                    <Step5 />
                  )}
                </div>
              </Transition>
              <div className="flex justify-between">
                {step > 1 && (
                  <button
                    type="button"
                    onClick={() => handleStepChange(step - 1)}
                    className="bg-gray-500 text-white py-2 px-8 rounded"
                  >
                    Previous
                  </button>
                )}
                {step < 5 ? (
                  <button
                    type="button"
                    onClick={() => handleStepChange(step + 1)}
                    className="bg-yellow-03 text-white font-bold py-2 px-8 rounded"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="bg-green-500 text-white py-2 px-8 rounded flex items-center justify-center"
                    disabled={loading}
                  >
                    {loading ? (
                      <ClipLoader color="#ffffff" size={20} />
                    ) : (
                      'Submit'
                    )}
                  </button>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default EventCreation;
